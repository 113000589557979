import { Vue, Component } from 'vue-property-decorator';
import BCardCode from '@core/components/b-card-code/BCardCode.vue';
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BModal,
  BForm,
  BCardText,
  BImg,
  BBadge,
  BLink,
  BFormDatepicker
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import { ListVue, listVueMixin } from '@/utility/pagination/list-vue.mixin';
import Pagination from '@/@core/components/paginations/Pagination.vue';
import { EmployeeListViewModel } from '@/api/api';
import {
  employeeListDispatcher,
  EmployeeListInput,
  employeeListNamespace
} from './store/employee-list.module';
import {
  ListGetter,
  ListGetterTypes
} from '@/utility/pagination/get-list.module-type';
import { DEFAULT_PAGE_SIZE } from '@/utility/pagination/pagination';
import { DropdownOption } from '@/utility/dropdowns/dropdownOptions';
import { enumToDropdownOptions, StringFormat } from '@/utility/utils';
import router from '@/router';
import { formatCurrency, formatDate } from '@/utility/utils';
import vSelect from 'vue-select';
import ApiClientFactory from '@/api/apiClientFactory';
import { USER_PERMISSION } from '@/utility/constants';

@Component({
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BModal,
    BForm,
    BCardText,
    Pagination,
    BImg,
    BBadge,
    BLink,
    vSelect,
    BFormDatepicker
  },
  mixins: [
    listVueMixin({
      initialInput: {
        itemsPerPage: DEFAULT_PAGE_SIZE,
        searchText: ''
      },
      dispatcher: employeeListDispatcher,
      debouncedWatchers: [(listInput) => listInput.searchText]
    })
  ]
})
export default class EmployeeList
  extends Vue
  implements ListVue<EmployeeListViewModel, EmployeeListInput> {
  @employeeListNamespace.Getter(ListGetter.state)
  readonly listState!: ListGetterTypes<
    EmployeeListViewModel,
    EmployeeListInput
  >[ListGetter.state];
  employeeListDispatcher = employeeListDispatcher;
  VUE_APP_API_BASE_HOST = process.env.VUE_APP_API_BASE_HOST;
  formatCurrency = formatCurrency;
  formatDate = formatDate;

  mounted() {
    employeeListDispatcher.load();
  }

  updateFilterStatus() {
    employeeListDispatcher.load(true);
  }

  edit(id: string) {
    router.push({ path: `employee-form/${id}` });
  }

  remove(id: string) {
    const client = new ApiClientFactory().employeeClient();
    client.delete(id).then(() => {
      employeeListDispatcher.load();
      // show toast
      this.$bvToast.toast(this.$t('DeleteEmployeeSuccessfully').toString(), {
        title: this.$t('Employee').toString(),
        toaster: 'b-toaster-bottom-right',
        variant: 'success'
      });
    });
  }

  HasSalePermission(permissions: string[] | null): boolean {
    if (permissions) {
      return permissions.indexOf(USER_PERMISSION.PERMISSION_ORDER) !== -1;
    }
    return false;
  }

  headers = [
    {
      label: this.$t('FullName'),
      field: 'fullName'
    },
    {
      label: this.$t('UserName'),
      field: 'userName'
    },
    {
      label: this.$t('SalePermissionLbl'),
      field: 'permissions'
    },
    {
      label: '',
      field: '_action',
      sortable: false,
      width: '1%'
    }
  ];
}
